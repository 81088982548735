import React from 'react'
import Index from '../../../Assets/Images/Index'
import BannerContent from '../../Common/BannerContent/BannerContent'
import TwoCol from '../../Common/TwoCol/TwoCol'
import TestimonialCard from '../../Common/TestimonialCard/TestimonialCard'
import Slider from "react-slick"
import SellingBox from '../../Common/SellingBox/SellingBox'
import Fade from 'react-reveal/Fade';
import "./Manage.css"


export default function Manage() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const testimonialContent = [
    {
      personImg: Index.kavitaKausal,
      name: "Kavita Kausal",
      position: "CEO",
      review: "It's been just a month since I created my ecommerce store for selling beauty products across India. First, I was really scared about how my store would turn out to be. But I am really happy with the visually appealing look of the store created."
    },
    {
      personImg: Index.pawasMahajan,
      name: "Pawas Mahajan",
      position: "CEO",
      review: "I used my existing domain on the Shopyxa platform. It really offers smooth functionality and easy-to-navigate features. Also, they offered me on-time tech support whenever I felt stuck at some point."
    },
    {
      personImg: Index.judieLastina,
      name: "Judie Lastina ",
      position: "CEO ",
      review: "If you are really interested to go for a cost-effective but fully customized ecommerce solution, Shopyxa is the right place. Their teams are really hard working in solving our queries. Recently I signed up for their paid membership for enhanced facilities."
    },


  ]
  const manageAnywhere = [
    {
      img: Index.manageAnywhere,
      title: "Fulfilling Order Management  From Your Mobile",
      content: "Control your orders, shipping details, and sales from a single dashboard. Shopyxa allows you to complete complex and time-consuming activities with less effort.",
      btnText: "Start Free Trial",
    }
  ]
  const fastSecure = [
    {
      img: Index.managefastSecure,
      title: "Secure Payment Management",
      content: "Get instant payments from your customers. They can pay with a few taps on the screen. Your customers can easily make payments with Shopyxa. Get on-the-spot notifications for the received amount.",
      btnText: "Start Free Trial",
    }
  ]
  const manageShipping = [
    {
      img: Index.manageShipping,
      title: "Get Automated: All-in-One Solution",
      content: "Whether handling inventory, orders, or payments, Shopyxa makes it possible for you to feel at ease when running your digital product-selling business. Various plans are available, each tailored to your specific requirements.",
      btnText: "Start Free Trial",
    }
  ]


  return (
    <main>
      <BannerContent
        bannerClass="manageBanner"
        title="Expand Your Business With Powerful Management Tool"
        cntnt="Single point management for various business operations with Shopyxa. Make your ecommerce store management simpler and more cost-efficient."
        img1={Index.manageBanner}
        btnText="Start Free Trial"
      />
      <section className='twocolSect commonPadding'>
        <div className="container">
          {manageAnywhere.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {fastSecure.map((data, indx) => {
            return (
              <TwoCol
                imgRight
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {manageShipping.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='testimonialSection commonPadding'>
        <div className="container">
          <div className="commonHeading">
            <Fade up><div className="h1">Meet the merchants who love Shopyxa </div></Fade>
          </div>
          <div className='testimonialSlider'>
            <Fade up>  <Slider {...settings}>
              {testimonialContent.map((data, indx) => {
                return (
                  <TestimonialCard
                    key={indx}
                    personImg={data.personImg}
                    name={data.name}
                    position={data.position}
                    review={data.review}
                  />
                )
              })}
            </Slider></Fade>
          </div>
        </div>
      </section>
      <SellingBox
        title="Start Selling Online for Free"
        btnText="Start Free Trial"
        cntntPos="center" />
    </main>
  )
}
