import React from 'react'
import "./SellingBox.css"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { signUpUrl } from '../../../Container/App'

export default function SellingBox({ title, btnText, cntntPos }) {
    return (
        <section className='greyBg commonPadding'>
            <div className="container">
                <div className={`commonHeading text-${cntntPos} mb-0`}>
                    <Fade up><div className="h1">{title}</div></Fade>
                    <div className="text-center">
                        <Flip top delay={100}> <a href={signUpUrl} className="btn btn-primary">{btnText}</a></Flip>
                    </div>
                </div>
            </div>
        </section>
    )
}
