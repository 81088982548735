import React from 'react'
import Index from '../../../Assets/Images/Index'
import BannerContent from '../../Common/BannerContent/BannerContent'
import TwoCol from '../../Common/TwoCol/TwoCol'
import { signUpUrl } from '../../../Container/App'
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import "./Sell.css"
import { useMediaQuery } from "@uidotdev/usehooks";

export default function Sell() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const sellonwebsite = [
    {
      img: Index.sellonwebsite,
      title: "Cutting-edge eCommerce Platform",
      content: "Experience all e-commerce solutions under one roof with Shopyxa. Sell your products and services on customized Shopyxa stores. Grow your revenue with fast-loading and optimized online stores.",
      btnText: "Start Free Trial",
    }
  ]
  const sellOnSocial = [
    {
      img: Index.sellonSocial,
      title: "Sell With No Worries",
      content: "Want your products in the spotlight? The answer is the Shopyxa store, where you can leverage the advantages of extended features and functionalities to reach the maximum audience throughout the country.",
      btnText: "Start Free Trial",
    }
  ]
  const sellonMarket = [
    {
      img: Index.sellonMarket,
      title: "Launch Optimized Store",
      content: "Theme layouts follow effective ideas to catch visitors' eyes. You can spice up your store with optimized titles, descriptions, and high-quality pictures. Hundreds of entrepreneurs trusted Shopxca to build a strong digital presence.",
      btnText: "Start Free Trial",
    }
  ]
  return (
    <main>
      <BannerContent
        bannerClass="sellBanner"
        title="All-in-one ecommerce Platform"
        cntnt="We aim to offer you the best-fit services tailored to your needs. Hurry Up!"
        img1={Index.sellBanner}
        absImg
        img2={Index.sellBanner2}
        btnText="Get Free Trial"
      />
      <section className='commonPadding sellAroundWorld'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="sellAroundWorld__Img">
              <Flip right><img src={prefersDarkMode ? Index.globeWhite : Index.globePng} alt="" /></Flip>
              </div>
            </div>
            <div className="col-md-7">
              <div className="sellAroundWorld__right">
                <div className="sellAroundWorld__rightTitle">
                  <Fade up>  <div className="h1 m-0">Sell Across the Nation with Ease </div></Fade>
                </div>
                <div className='sellAroundWorld__grid'>
                  <div className='sellAroundWorld__box'>
                    <Fade up> <div className='title'>10</div></Fade>
                    <Fade up><div className='h6'>Payment Gateway</div></Fade>
                  </div>
                  <div className='sellAroundWorld__box'>
                    <Fade up><div className='title'>25321</div></Fade>
                    <Fade up><div className='h6'>Merchant on Shopyxa </div></Fade>
                  </div>
                  <div className='sellAroundWorld__box'>
                    <Fade up> <div className='title'>20+</div></Fade>
                    <Fade up> <div className='h6'>Cities Covered </div></Fade>
                  </div>
                  <div className='sellAroundWorld__box'>
                    <Fade up> <div className='title'>₹2.3M</div></Fade>
                    <Fade up><div className='h6'>Economic Activity</div></Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className='twocolSect commonPadding'>
        <div className="container">
          {sellonwebsite.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {sellOnSocial.map((data, indx) => {
            return (
              <TwoCol
                imgRight
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {sellonMarket.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='commonPadding'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="startSellingBox">
                <div className="row ">
                  <div className="col-md-5">
                  <Flip left> <img src={Index.startSelling} className="startSellingBox__img ms-auto d-block img-fluid" alt="" /></Flip>
                  </div>
                  <div className="col-md-7">
                    <div className="startSellingBox__cntnt">
                      <Fade up> <div className="h6"> Your online store is just a few clicks away!</div></Fade>
                      <Fade up> <div className="h1"> Start with 15 days Free Trial</div></Fade>
                      {/* <button className='btn btn-dark'>Start Free Trial </button> */}
                      <Fade up> <a href={signUpUrl} className="btn btn-dark">Get Started</a></Fade>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
