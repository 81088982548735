import React from 'react'
import './BannerContent.css'
import { signUpUrl } from '../../../Container/App'
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
export default function BannerContent({ title, cntnt, img1, absImg, img2, btnText, form, bannerClass, handleEmail, submitEmail, error }) {

    return (
        <section className={`${bannerClass ? bannerClass : null} banner commonPadding `}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <div className="banner__cntnt">
                            <Fade delay={400}>
                                <div className="banner__cntntInnr">
                                    <h1>{title}</h1>
                                    <div className="h5 fontRegular">{cntnt}</div>
                                </div>
                            </Fade>
                            <Fade delay={500}>
                                {form ?
                                    <div>
                                        <form onSubmit={submitEmail} className="bannerForm d-flex">
                                            <input type="email" className="form-control" placeholder="Email Address" aria-label="Email Address" aria-describedby="button-addon2" onChange={handleEmail} />
                                            <button className="btn btn-primary" type="submit">{btnText} </button>
                                        </form>
                                        {error.show ?
                                            <p style={{ color: "#ff0000" }}><small>{error.msg}</small></p> : null
                                        }
                                    </div>
                                    :
                                    <div className="bannerForm">
                                        {/* <button className="btn btn-primary" type="button" >{btnText}</button> */}
                                        <a href={signUpUrl} className="btn btn-primary">{btnText} </a>
                                    </div>
                                }
                            </Fade>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className='bannerImg'>
                            <Fade delay={600}>
                                <img src={img1} className="img-fluid w-100 d-block" alt="" />
                            </Fade>
                            {absImg ?
                                <Flip top delay={700}>
                                    <div className='bannerAbsImg'>
                                        <img src={img2} className="img-fluid w-100 d-block" alt="" />
                                    </div> </Flip> : null}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
